<template>
    <div class="box">
        <!-- {{ codes }}
        {{ userInfo }}
        <div @click="getUserInfo">
            登录微信asdas
        </div> -->
        <loading :show="showLoading" :msg="'登录中...'"></loading>
    </div>
</template>
<script>
import { passenger } from "@/api";
import { Toast } from "vant";
import Loading from "./comm/loading-login.vue";
// import CONFIG from "../../config";
export default {
    components: {
        Loading: Loading
    },
    data() {
        return {
            codes: "",
            userInfo: "",
            showLoading: false
        };
    },
    mounted() {
        this.showLoading = true
        this.getUserInfo()
    },
    methods: {
        getQueryString: function (name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(decodeURI(r[2]));
            }
        },
        getUserInfo() {
            let params = {
                code: this.getQueryString("code"),
            };
            passenger.getCodeToUser(params)
                .then((res) => {
                    if(res.data.code == 200) {
                        this.showLoading = false
                        localStorage.setItem("token", res.data.data.access_token);
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                        if(this.$route.query.resouce == 'my-yy') {
                            this.$router.replace({
                                name: 'my-yy'
                            })
                        } else if (this.$route.query.resouce == 'sjckyy') {
                            this.$router.replace({
                                name: 'sjckyy'
                            })
                        } else {
                            if(res.data.data.user.types==4) {
                                setTimeout(() => {
                                    this.$router.replace({
                                            name: 'sjfb-list',
                                            query: {}
                                        })
                                }, 500)
                            } else {
                                this.$router.replace({
                                    name: 'gkyy-list'
                                })
                            }
                        }
                        
                    } else {
                        Toast.fail('登录失败')
                        setTimeout(() => {
                        //         window.location.href =
                        // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4d12960aef5beb75&redirect_uri=http://yjck.yayale.top/login&response_type=code&scope=snsapi_userinfo&state=STATE`
                        }, 1500)
                    }
                    // this.userInfo = JSON.stringify(res)
                    
                    // console.log(res)
                })
                .catch(() => {
                    Toast.fail('登录失败')
                        setTimeout(() => {
                        //         window.location.href =
                        // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4d12960aef5beb75&redirect_uri=http://39xmby.natappfree.cc/login&response_type=code&scope=snsapi_userinfo&state=STATE`
                        }, 1500)
                });
        },
    },
};
</script>
